/*
HARD Overrides
*/
.visuPlaceholder__layerstree {
  background-color: #ffffff !important; }

.skeleton {
  background: linear-gradient(to left, #ffffff, #ffffff);
  border: none; }

/* Panneau des vues */
.bp3-dark .active .bp3-button.bp3-minimal.header-button {
  background: none !important;
  background-color: #37A592 !important; }

/* Arbre des couches */
.bp3-dark .layerstree-group__label-button H5 {
  color: #ffffff !important; }

.bp3-dark .layerstree-node {
  color: #ffffff !important;
  border-color: #2C8475 !important; }

.bp3-dark .layerstree-group--active .bp3-collapse-body {
  background-color: #2C8475 !important;
  border-color: #2C8475 !important; }

.visualizer-view.is-layers-tree-visible .map-navigation {
  background: linear-gradient(to bottom, #2C8475, #2C8475) !important; }

.bp3-dark .map-navigation__content {
  scrollbar-color: #37A592 #2C8475 !important; }

.bp3-navbar.bp3-dark, .bp3-dark .bp3-navbar {
  background-color: #183933 !important; }

.bp3-dark .layerstree-node .layerstree-node-content__options__button--active {
  background: transparent !important; }
  .bp3-dark .layerstree-node .layerstree-node-content__options__button--active svg {
    filter: none !important;
    color: #2C8475; }

.map-navigation__title {
  padding: 0 0.5rem;
  font-weight: 400;
  letter-spacing: 1.25px;
  font-size: 1.3rem; }

/* Filtres */
.bp3-dark .filters-panel__form {
  scrollbar-color: #2C8475 !important; }

.bp3-dark .filters-panel .bp3-select-popover .bp3-menu,
.bp3-dark .filters-panel__form {
  scrollbar-color: #37A592 #2C8475 !important; }

.filters-panel {
  background-color: #2C8475 !important; }
  .filters-panel__arrow::after {
    background: #2C8475 !important; }

.bp3-dark .bp3-menu {
  background-color: #2C8475 !important; }

/* Couleur texte */
.bp3-dark .bp3-tag {
  background-color: #37A592;
  color: #ffffff; }

.bp3-tag.bp3-intent-primary {
  background: #2C8475;
  color: #ffffff; }

.bp3-popover.bp3-dark .bp3-popover-content,
.bp3-dark .bp3-popover .bp3-popover-content {
  background-color: #37A592;
  color: #ffffff; }

/* Bouton table attributaire */
.bp3-button.bp3-minimal.bp3-intent-primary {
  color: #2C8475; }

.visualizer .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip, .visualizer .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip, .visualizer .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #37A592 !important; }

/* Page contacts */
.bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon.bp3-icon-info-sign, .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon-large, .bp3-dark .bp3-button:not([class*="bp3-intent-"]) .bp3-icon-standard, .bp3-dark .bp3-button:not([class*="bp3-intent-"])[class*="bp3-icon-"]::before {
  color: #ffffff; }

.bp3-dark .active button#Contact.bp3-button.bp3-minimal.header-button {
  background: none !important; }

/* Boutons de controle de carte */
.bp3-dark .mapboxgl-ctrl-group {
  background-color: #2C8475 !important; }

.bp3-dark .mapboxgl-ctrl-search__input {
  color: #ffffff; }

.mapboxgl-ctrl
.search-results__item.search-results__item--active
button:not(:disabled):hover {
  background-color: #37A592; }

.bp3-card.bp3-elevation-0.search-results {
  background-color: #2C8475; }

.bp3-dark .bp3-input {
  background: #37A592;
  color: #ffffff; }

.bp3-dark .mapboxgl-ctrl-group.mapboxgl-ctrl-home button:hover,
.bp3-dark .mapboxgl-ctrl-group.mapboxgl-ctrl-home button:focus,
.bp3-dark .mapboxgl-ctrl-group.mapboxgl-ctrl-print button:hover,
.bp3-dark .mapboxgl-ctrl-group.mapboxgl-ctrl-print button:focus,
.bp3-dark .mapboxgl-ctrl-group.mapboxgl-ctrl-share button:hover,
.bp3-dark .mapboxgl-ctrl-group.mapboxgl-ctrl-share button:focus,
.bp3-dark .mapboxgl-ctrl-group.mapboxgl-ctrl-report button:hover,
.bp3-dark .mapboxgl-ctrl-group.mapboxgl-ctrl-report button:focus,
.bp3-dark .mapboxgl-ctrl-group .mapboxgl-ctrl-background-styles button:hover,
.bp3-dark .mapboxgl-ctrl-group .mapboxgl-ctrl-background-styles button:focus {
  background-color: #37A592; }

label.bp3-control.bp3-radio.bgLayer-radio {
  color: #ffffff; }

/* Mini fiche */
.view-details .details__subtitle {
  color: #2C8475; }

.bp3-button .bp3-icon, .bp3-button .bp3-icon-standard, .bp3-button .bp3-icon-large {
  color: #6FD6C8; }

.bp3-button:not([class*="bp3-intent-"]) {
  background-color: #2C8475; }

.main {
  display: flex; }

.main .map-navigation {
  padding: 0 !important; }
  .main .map-navigation__header .appName-title-logo {
    margin-top: 0; }

.layerstree-group:first-child {
  margin-top: 0; }

.layerstree-panel-list {
  width: 100%; }

.main .layerNode-options__buttons--active .icon, .main .layerNode-options__buttons--active svg, .layerNode-widgets__buttons--active .icon, .layerNode-widgets__buttons--active svg {
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(140deg); }

.details .details__info-administrative {
  text-align: center;
  margin-bottom: 4px; }

.details .details__list .details__column-label {
  white-space: normal;
  font-size: .85rem;
  width: 15.5rem;
  padding-right: .5rem; }

.details .details__list .details__column-value {
  vertical-align: bottom; }

.visualizer .interactive-map__legends .tf-legend {
  max-width: none; }

.visualizer .interactive-map__legends .tf-legend__title {
  align-self: center; }

.mapboxgl-ctrl:hover {
  color: #202B33; }

.interactive-map .tf-legend {
  margin-bottom: 1em; }

.visualizer .mapboxgl-control-container {
  transition: all .2s ease-in; }

.visualizer .mapboxgl-ctrl-top-left,
.visualizer .mapboxgl-ctrl-bottom-left {
  transform: none;
  transition: all .2s ease-in; }

.visualizer .mapboxgl-ctrl-bottom-left {
  transform: none;
  margin: -0.5rem 0.5rem;
  left: 0rem;
  right: 22rem;
  transition: all .2s ease-in; }
  .visualizer .mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
    float: none; }

.visualizer.visualizer--with-layers-tree .mapboxgl-ctrl-bottom-left {
  transform: none;
  margin: -0.5rem 0.5rem;
  left: 22.5rem;
  right: 22rem;
  transition: all .2s ease-in; }

.visualizer.visualizer--with-layers-tree.visualizer--with-table .mapboxgl-ctrl-bottom-left {
  left: 0;
  transition: none; }

.visualizer.visualizer--with-layers-tree.visualizer--with-table .mapboxgl-ctrl-logo {
  right: 19.5rem;
  transition: none; }

.visualizer .mapboxgl-popup .mapboxgl-popup-content {
  text-align: center; }

.visualizer .mapboxgl-ctrl-logo {
  position: absolute;
  right: 10px;
  bottom: 15px;
  transition: all .2s ease-in; }

.visualizer.visualizer--with-layers-tree .mapboxgl-ctrl-logo {
  right: -38px;
  bottom: 15px; }

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .visualizer .interactive-map__legends {
    right: 3.25rem; } }

@media (min-width: 320px) and (max-width: 768px) and (orientation: portrait) {
  .visualizer .interactive-map__legends {
    right: 3.75rem; } }
